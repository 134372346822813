import React from 'react';
import Gallery from "./Gallery"
import ReactModal from "react-modal"
import '../styles/lightbox.css';
import { useSwipeable, Swipeable } from 'react-swipeable'
class Lightbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            show: false,
            showLoader: false,
            imgIndexInLightbox: 0,
            screenWidth: null,
            screenHight: null
        };

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.keyboardArrowsDown = this.keyboardArrowsDown.bind(this);
    }

    componentDidMount() {
        document.body.addEventListener("keydown", this.keyboardArrowsDown);
        window.addEventListener("resize", this.updateWindowDimensions);
        this.updateWindowDimensions();
    }
    componentWillUnmount() {
        document.body.removeEventListener("keydown", this.keyboardArrowsDown);
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    keyboardArrowsDown = event => {
        if(this.state.show === true) {
            if(event.keyCode === 37){
                this.previousImage();
            }
            else if (event.keyCode === 39) {
                this.nextImage();
            }
        }
    }

    updateWindowDimensions() {
        this.setState({ screenWidth: window.innerWidth, screenHight: window.innerHeight });
    }

    nextImage = () => {
        this.startLoading();
        if (this.state.imgIndexInLightbox >= this.props.imageArray.length - 1) {
            this.setState({ imgIndexInLightbox: 0 })
        }
        else {
            this.setState({ imgIndexInLightbox: this.state.imgIndexInLightbox + 1 })
        }
    }

    previousImage = () => {
        this.startLoading();
        if (this.state.imgIndexInLightbox <= 0) {
            this.setState({ imgIndexInLightbox: this.props.imageArray.length - 1})
        }
        else {
            this.setState({ imgIndexInLightbox: this.state.imgIndexInLightbox - 1 })
        }
    }

    startLoading = () => {
        /* 
        set false to load (so "loading" / progress-bar can appear) on opening 
        because if you would do it on close, it would appear after closing for amount of seconds in 
        closeTimeoutMS which makes a little blink.
        */
        this.setState({ loaded: false })
        /* 
        Wait {time} seconds and if it is still not loaded, then show loading sign
        this prevents blink in the case that I have a fast internet and loading sign 
        shows immediately and then photo right after it, it can make a blink
        */
        window.setTimeout(() => {
            if (!this.state.loaded) {
                this.setState({ showLoader: true })
            }
        }, 1000);
    }

    openLightbox = (index) => {
        this.setState({ imgIndexInLightbox: index, show: true });
    }

    render() {
        return (
            <>
                <Gallery
                    imageArray={this.props.imageArray}
                    imgIndexToOpen={this.openLightbox}
                    columns={this.props.galleryColumns}>
                </Gallery>

                <ReactModal
                    isOpen={this.state.show}
                    onRequestClose={() => this.setState({ show: false, loaded: false, showLoader: false })}
                    contentLabel={"Gallery"}
                    closeTimeoutMS={200}
                    className="lightbox-content"
                    overlayClassName="lightbox-overlay"
                    ariaHideApp={false}
                    onAfterOpen={this.startLoading}
                    // onAfterOpen={(e) => this.onAfterOpen}
                    // onAfterClose={(e) => this.onAfterClose}
                    >
                    <Swipeable 
                        className="lightbox-content" 
                        onSwipedLeft={() => this.nextImage()} 
                        onSwipedRight={() => this.previousImage()}
                        style={{ maxHeight: this.state.screenHight }}>
                    
                            {/* left */}
                            {this.state.screenWidth > 900 && 
                                <div className="close-and-arrow-left_container">
                                    <div className="close-button_container">
                                        {this.state.screenWidth >= 1050 && 
                                            <button
                                                className="close-button"
                                                onClick={() => this.setState({ show: false, showLoader: false, loaded: false })}>
                                                CLOSE
                                            </button>
                                        }
                                        {this.state.screenWidth < 1050 && 
                                            <button
                                                className="close-button-mobile"
                                                onClick={() => this.setState({ show: false, showLoader: false, loaded: false })}>
                                                ✕
                                            </button>
                                        }
                                    </div>
                                    <div className="arrow-left_container">
                                        <button className="arrow arrow-left" onClick={this.previousImage}>←</button>
                                    </div>
                                </div>
                            }
                            {/* top */}
                            {this.state.screenWidth <= 900 && 
                                <div className="close-button-container-mobile">
                                    <button
                                        className="close-button-mobile"
                                        onClick={() => this.setState({ show: false, showLoader: false, loaded: false })}>
                                        ✕
                                    </button>
                                </div>
                            }

                            {/* center */}
                            <p
                                className="blinking-text loading-text"
                                style={this.state.showLoader ? { display: 'block' } : { display: 'none' }}>
                                LOADING
                            </p>

                            {/* center */}
                            <img
                                src={this.props.imageArray[this.state.imgIndexInLightbox].photo.url}
                                alt={this.props.imageArray[this.state.imgIndexInLightbox].photo.alt}
                                className={this.state.loaded && !this.state.showLoader ? "lightbox-image" : "lightbox-image_hidden"}
                                onLoad={() => this.setState({ loaded: true, showLoader: false })}>
                            </img>

                            {/* right */}
                            {this.state.screenWidth > 900 && 
                                <div className="blank-and-arrow-right_container">
                                    {this.state.screenWidth >= 1050 && 
                                        <div className="blank_container">CLOSE</div>
                                    }
                                    {this.state.screenWidth < 1050 && 
                                        <div className="blank_container-mobile">✕</div>
                                    }
                                    <div className="arrow-right_container">
                                        <button className="arrow arrow-right" onClick={this.nextImage}>→</button>
                                    </div>
                                </div>
                            }

                            {/* bottom */}
                            {this.state.screenWidth <= 900 && 
                                <div className="arrow-buttons-container-mobile">
                                    <button className="arrow arrow-left" onClick={this.previousImage}>←</button>
                                    <button className="arrow arrow-right" onClick={this.nextImage}>→</button>
                                </div>
                            }

                    </Swipeable>
                </ReactModal>
            </>
        )
    }
}


export default Lightbox;
