import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

const LinkTag = styled(Link)`
    text-decoration: none;
    color: #000;
    font-size: 20px;
    letter-spacing: 2px;
    margin: 0 2.43vw 65px 2.43vw;
    display: block;

    @media screen and (min-width: 1600px) {
        font-size: 22px;
    }

    @media screen and (max-width: 1100px) {
        font-size: 18px;
    }

    @media screen and (max-width: 750px) {
        margin: 0 4vw 75px 4vw;
    }
    
    &:before {
        margin-right: 15px;
        content: "←";
        transform-origin: 50% 50%;
        display: inline-block;
        transition: transform .2s ease-out;
        font-family: 'KontrapunktMiki-Regular';
        font-size: 18px;

        @media screen and (min-width: 1600px) {
            font-size: 20px;
        }

        @media screen and (max-width: 1100px) {
            font-size: 16px;
        }
    }

    &:hover:before {
        transform: translateX(-8px);
    }
`

const LinkBack = ({ linkURL, linkName, style }) => (
    <LinkTag to={linkURL} style={style}>{linkName}</LinkTag>
)

export default LinkBack
