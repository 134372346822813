import React from "react"
import styled from "styled-components"
import LinkTo from "./LinkTo"

const FooterContainer = styled.div`
    display: flex;
    margin: 50px 2.43vw 10px;
    flex-wrap: wrap;

    @media screen and (max-width: 750px) {
        flex-direction: column;
        margin: 0 4vw 10px;
    }
`
const PTag = styled.p`
    font-weight: 300;
    letter-spacing: 2px;
    font-size: 18px;
    line-height: 26px;
    text-align: justify;

    @media screen and (min-width: 1600px) {
        font-size: 20px;
    }

    @media screen and (max-width: 1100px) {
        font-size: 16px;
    }
`
const DivTag = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-end;
    justify-content: space-between;
    
    @media screen and (max-width: 750px) {
        align-items: flex-start;

        a {
            margin-bottom: 35px;
        }

        a:last-child {
            margin-bottom: 0;
        }
    }
`

const DivTagText = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    @media screen and (max-width: 1100px) {
        flex: 2;
    }

    @media screen and (max-width: 750px) {
        margin-bottom: 75px;
    }
`

const Copyright = styled.p`
    flex-basis: 100%;
    margin-top: 70px;
    text-align: center;
    font-size: 13px !important;
    font-weight: 300;

    @media screen and (max-width: 750px) {
        margin: 75px 0 10px;
        font-size: 12px !important;
    }
`

const currentYear = new Date().getFullYear();

const Footer = ({ shortText, footerLinksArray }) => (
    <footer>
        <FooterContainer>
            <DivTagText>
                <PTag>{shortText}</PTag>
                <div style={{ paddingTop: "20px" }}>
                    <LinkTo linkURL="/about/" linkName="More about me" style={{ paddingRight: "16px" }}></LinkTo>
                </div>
            </DivTagText>
            <DivTag>
                {footerLinksArray.map(footerLink => (
                    <LinkTo linkURL={footerLink.URL} linkName={footerLink.name} key={footerLink.name}></LinkTo>
                ))}
            </DivTag>

            <Copyright>Copyright © {currentYear}. Andrea Blahová</Copyright>
        </FooterContainer>
    </footer>
)

export default Footer
