import React from 'react';
import Img from "gatsby-image"
import styled from "styled-components"
import Masonry from 'react-masonry-component';


const Gallery = ({ imageArray, imgIndexToOpen, columns }) => {
    const images = imageArray.map((image, index) => {
        if(columns == 2) {
            return (
                    <ImgContainerTwoColumns 
                        onClick={() => imgIndexToOpen(index)} 
                        key={image.photoSharp.childImageSharp.fluid.src + index} 
                        >
                        <ImgTag fluid={image.photoSharp.childImageSharp.fluid}>
                        </ImgTag>
                    </ImgContainerTwoColumns>
            );
        } else if (columns == 3) {
            return (
                <ImgContainerThreeColumns 
                    onClick={() => imgIndexToOpen(index)} 
                    key={image.photoSharp.childImageSharp.fluid.src + index} 
                    >
                    <ImgTag fluid={image.photoSharp.childImageSharp.fluid}>
                    </ImgTag>
                </ImgContainerThreeColumns>
            );
        }
    });

    return (
        <>
            <MasonryGrid>
                {images}
            </MasonryGrid>
        </>
    );
}

const ImgTag = styled(Img)`
    width: 100%;
    max-width: 1000px;
    cursor: pointer;
    max-height: 100vh;    
`

const ImgContainerTwoColumns = styled.div`
    width: calc( 50% - 2.43vw );
    max-height: 100vh; 
    margin-bottom: 2.43vw;
    margin-left: 2.43vw;
    
    @media screen and (max-width: 850px) {  
        width: calc( 100% - 2.43vw );
    }

    @media screen and (max-width: 750px) {  
        width: calc( 100% - 4vw );
        margin-left: 4vw;
        margin-bottom: 50px;
    }
`

const ImgContainerThreeColumns = styled.div`
    width: calc( 33.33% - 2.43vw );
    max-height: 100vh; 
    margin-bottom: 2.43vw;
    margin-left: 2.43vw;

    @media screen and (max-width: 1200px) {  
        width: calc( 50% - 2.43vw );
    }

    @media screen and (max-width: 750px) {  
        width: calc( 100% - 4vw );
        margin-left: 4vw;
        margin-bottom: 50px;
    }
`

const MasonryGrid = styled(Masonry)`
    width: calc( 100% - 2.43vw );
    margin: 48px 2.43vw 48px 0;

    @media screen and (max-width: 750px) {  
        width: calc( 100% - 4vw );
        margin: 75px 4vw 25px 0;
    }
`
export default Gallery;