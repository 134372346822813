import React from "react"
import Header from '../components/Header.js'
import Footer from '../components/Footer.js'
import Transition from '../components/Transition.js'

const Layout = ({ children, footerShortText, footerLinksArray, headerPosition, location }) => (
    <div className="max-width-1600">
        <Header siteTitle="ANDREA BLAHOVÁ" headerPosition={headerPosition}></Header>
        <Transition location={location}>
            {children}
            <Footer shortText={footerShortText} footerLinksArray={footerLinksArray}></Footer>
        </Transition>
    </div>
)

export default Layout
